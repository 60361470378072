<template>
  <div class="payment-history">
    <div class="row align-items-center px-1">
      <b-form-group class="col-md-3 p-0 mb-0 mt-1">
        <label>{{ $t("Select User") }}</label>
        <user-search
          v-model="form.userId"
          class="card mr-2"
          placeholder="All Users"
          :multiple="true"
        />
      </b-form-group>
      <b-form-group class="col-md-4 px-0">
        <label class="mr-1">{{ $t("Date Range") }}</label>
        <date-range-filter
          :till-day-before="true"
          @change="dateChange"
        />
      </b-form-group>
      <div
        v-if="!IS_MOBILE() || usingCustomDateRange"
        class="d-flex col-sm-12 col-md-4 px-0"
      >
        <b-form-group class="col-6 px-0">
          <label>Start Date</label>
          <div class="d-flex align-items-center">
            <b-form-datepicker
              v-model="form.start_date"
              :date-format-options="IS_MOBILE() ? COMMON.MOBILE_DATE_PICKER_FORMAT : COMMON.DATE_PICKER_FORMAT"
              :max="form.end_date"
              :disabled="!usingCustomDateRange"
            />
          </div>
        </b-form-group>
        <b-form-group class="col-6 px-0 pl-1">
          <label>End Date</label>
          <div class="d-flex align-items-center">
            <b-form-datepicker
              v-model="form.end_date"
              :min="form.start_date"
              :max="maxDate()"
              :disabled="!usingCustomDateRange"
              :date-format-options="IS_MOBILE() ? COMMON.MOBILE_DATE_PICKER_FORMAT : COMMON.DATE_PICKER_FORMAT"
              :offset="-80"
            />
          </div>
        </b-form-group>
      </div>
      <div class="col-md-8 d-flex justify-content-end">
        <div class="mr-2 text-muted text-center">
          <b>{{ $t("Total Time") }}</b> <h3 class="ml-1">
            {{ CONVERT_HM(totalTime) }}
          </h3>
        </div>
        <div class="text-center text-muted">
          <b>{{ $t("Total Amount") }}</b> <h3 class="ml-1">
            {{ `${currency ? currency : '$'}` }} {{ number_format(totalAmount, 2) }}
          </h3>
        </div>
      </div>
    </div>

    <payment-history-table
      :rows="rows"
      :is-processing="isProcessing"
      :total-users="totalUsers"
      :currency="currency"
      @get-data="getUserInvoiceDates"
    />
  </div>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import moment from 'moment'
import {
  BFormDatepicker, BFormGroup,
} from 'bootstrap-vue'
import UserSearch from '@/views/common/components/UserSearch.vue'
import DateRangeFilter from '@/views/common/components/DateRangeFilter.vue'
import { setComponentReady } from '@/views/tour/tour'
import PaymentHistoryTable from './PaymentHistoryTable.vue'

export default {
  components: {
    BFormDatepicker,
    BFormGroup,
    UserSearch,
    PaymentHistoryTable,
    DateRangeFilter,
  },
  data() {
    return {
      usingCustomDateRange: false,

      form: {
        start_date: moment(this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start).startOf('month').format('YYYY-MM-DD 00:00:00'),
        end_date: this.maxDate(),
      },
      projectId: this.$store.state.project.selectedProject,
      currency: this.$store.state.project.company?.currency ?? '$',
      today: moment().format('YYYY-MM-DD 00:00:00'),
      rows: [],
      isProcessing: false,
      allInvoicedDates: [],
      invoicedDates: [],
      selectedRow: null,
      totalTime: 0,
      totalAmount: 0,
      totalUsers: 0,
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.getUserInvoiceDates()
      },
    },
  },
  methods: {
    maxDate() {
      return this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start
    },
    dateChange(range) {
      if (!range.startTime && !range.endTime) {
        this.usingCustomDateRange = true
        return null
      }
      this.form.start_date = range.startTime
      this.form.end_date = range.endTime
      this.usingCustomDateRange = false
      return null
    },
    getUserInvoiceDates(page = 1, perPage = 5) {
      this.isProcessing = true

      useApollo.payroll.getUserInvoiceDates({
        first: perPage,
        page,
        projectUid: this.projectId,
        startTime: moment(this.form.start_date).format('YYYY-MM-DD 00:00:00'),
        endTime: moment(this.form.end_date).add(1, 'days').format('YYYY-MM-DD 00:00:00'),
        timezone: this.$store.state.project.company.timezoneOffset.filter,
        selectedUser: this.form.userId?.length ? this.form.userId : null,
      }).then(response => {
        this.allInvoicedDates = response.data?.users?.data

        this.$nextTick(() => { this.getUserTotalInfo(page, perPage) })
        setComponentReady()
      })
    },
    getUserTotalInfo(page = 1, perPage = 5) {
      useApollo.payroll.getUserTotals({
        first: perPage,
        page,
        projectUid: this.projectId,
        startTime: moment(this.form.start_date).format('YYYY-MM-DD 00:00:00'),
        endTime: moment(this.form.end_date).add(1, 'days').format('YYYY-MM-DD 00:00:00'),
        timezone: this.$store.state.project.company.timezoneOffset.filter,
        selectedUser: this.form.userId?.length ? this.form.userId : null,
      }).then(response => {
        const { data } = response.data.users
        this.totalUsers = response.data.users.total
        data.forEach((user, index) => {
          const userInvoice = this.allInvoicedDates.find(invoice => invoice.uuid === user.uuid)
          const invoicedData = userInvoice.invoices ? userInvoice.invoices?.data?.map(item => ({ ...item, key: moment(item.invoicedAt).format('MMM YYYY') })) : ''
          const availableDate = [...new Set(invoicedData.map(item => item.key))]
          const dataGroupByDate = availableDate.map(date => ({
            amount: invoicedData.filter(item => item.key === date).reduce((p, c) => p + Number(c.paidAmount.toFixed(2)), 0),
            date,
          }))
          data[index].chartData = {
            amount: dataGroupByDate.map(item => item.amount.toFixed(2)).reverse(),
            invoicedAt: dataGroupByDate.map(item => item.date).reverse(),
            data: invoicedData,
          }
        })
        this.rows = data
        this.totalTime = data.map(item => item.activityDuration).reduce((a, b) => a + b, 0)
        this.totalAmount = data.map(item => item.activityAmount).reduce((a, b) => a + b, 0)
        setComponentReady()
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
